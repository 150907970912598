
import { logInPassword, updatePassword } from '@/api/authModule';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseInputPassword from '@/components/baseComponents/BaseInputPassword.vue';
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import { activeFlow, startFlow, setCurrentStage, getCompletedFlows } from '@/api/flowSession';
import { getNextPage , enterToTheFlow } from '@/utils/redirectUtils'
import { getUserSettings } from '@/utils/updateInfo';
import { universalRegExpChecker } from '@/utils/validationUtils';
import { Component, Vue, Watch } from 'vue-property-decorator';
// import { loadHubSpotChat } from '@/hubSpotChat';

@Component({
  components: {
    TitleDescriptionUniversal,
    BaseInputPassword,
    BaseButton,
  },
})
export default class RestorePasswordPage extends Vue {
  password = '';
  passwordConfirm = '';
  passwordDescription = '';
  passwordConfirmDescription = '';
  isPreloaderShow = false;
  errors = {
    password: false,
    passwordConfirm: false,
  };
  isPasswordValid = false;
  isPasswordConfirmValid = false;
  timeout: null | number = null;

  get correctFields(): boolean {
    return this.password === this.passwordConfirm;
  }
  get email(): string {
    return this.$store.state.user.email;
  }

  onTypingValidation(fieldName: string): void {
    if (fieldName === 'password') {
      this.isPasswordValid = universalRegExpChecker('password', this.password);
      this.errors.password = false;
      this.passwordDescription = this.isPasswordValid
        ? ''
        : 'Password minimum of 8 characters: must contain uppercase & lowercase, at least 1 number, special characters optional';
    }
    if (fieldName === 'passwordConfirm') {
      this.isPasswordConfirmValid = this.password === this.passwordConfirm;
      this.errors.passwordConfirm = false;
      this.passwordConfirmDescription = this.isPasswordConfirmValid ? '' : 'Passwords must match';
    }
  }

  checkField(fieldName: string): void {
    if (fieldName === 'password') this.errors.password = !universalRegExpChecker('password', this.password);
    if (fieldName === 'passwordConfirm') this.errors.passwordConfirm = this.password !== this.passwordConfirm;
  }

  async nextButtonAction(): Promise<void> {
    try {
      this.isPreloaderShow = true;
      await updatePassword(this.password);
      await logInPassword({
        email: this.email,
        password: this.password,
      });
      await getUserSettings();
      await enterToTheFlow();

      let activeSession: any = false;

      const isRegistrationCompleted = this.$store.getters['user/isRegistrationCompleted'];
      if (isRegistrationCompleted) {
        const { data }: any = await activeFlow();
        activeSession = data;
      }

      const currentFlow = this.$store.getters['flowStages/getCurrentFlow'];  
    
      if (currentFlow) {
        await startFlow(currentFlow);
        await setCurrentStage(getNextPage());
        this.$router.push({name: 'PortalPage'})
      } else if (activeSession) {
        const { flowVersion, currentPage } = activeSession;
        this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion });
        await startFlow(flowVersion);
        if (currentPage) {
          this.$store.commit('flowStages/setAvailability', currentPage )
          this.$router.push({name: 'PortalPage'})       

          await setCurrentStage(getNextPage());
          this.$router.push({name: 'PortalPage'})
        }
      } else {
        this.$router.push({name: 'PortalPage'})
      }

      const { data: completedFlows } = await getCompletedFlows();
      this.$store.commit('flowStages/setCompletedFlowsArr', completedFlows)

      // await loadHubSpotChat();
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isPreloaderShow = false;
    }
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }

  @Watch('password')
  passwordWatcher(): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.onTypingValidation('password');
    }, 500);
  }

  @Watch('passwordConfirm')
  passwordConfirmWatcher(): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.onTypingValidation('passwordConfirm');
    }, 500);
  }
}
